/* phone size is the base */

.section-header {
    background-color: $darkgrayishblue;
    padding: $measure-lg;

    @include for-size(tablet-md) {
        padding: $measure-xl;
    }
    
    > .header-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        > .logo-col {
            /* banner uses same height on phone */
            width: 128px;
            height: 98px;
        
            @include for-size(laptop) {
                display: none;
            }
        }

        > .logo-row {
            display: none;
            
            @include for-size(laptop) {
                display: block;

                > img {
                    height: $measure-xxl;
                }
            }
        }

        > .menu-ul {
            display: none;
            list-style-type: none;
            text-align: end;

            @include for-size(laptop) {
                display: block;
            }

            > li {

                > a {
                    font-size: $font-sm;
                    text-transform: uppercase;
                    color: #fff;

                    &:hover {
                        transition: $hover;
                        color: $greenyellow;
                    }

                    @include for-size(desktop) {
                        font-size: $font-md;
                    }
                }
            }
        }

        > .menu-btn {

            @include for-size(laptop) {
                display: none;
            }
        }
    }
}

.section-banner {
    padding: 0;
    background-color: $darkgrayishblue;

    > .banner-container {
        position: relative;

        > .banner {
            display: block;
            margin-left: auto;
            width: 100%;
            /* Same height as .logo-col class. 2 * $measure-lg, because of the padding in .section-header class. Banner should have the same height as header for mobile. */
            height: 98px + (2 * $measure-lg);
            background-color: $darkgrayishblue;

            @include for-size(tablet) {
                height: 100%;
            }
        }
    
        > .banner-heading {
            letter-spacing: calc(2 * #{$letter-spacing});
            position: absolute;
            left: 0;
            top: 0;
            margin-top: $measure-md;
            margin-left: $measure-md;
            font-size: $font-xxs;
            font-weight: 700;
            font-style: italic;
            color: $greenyellow;
            text-transform: uppercase;

            @include for-size(phone) {
                font-size: $font-sm;
                margin-top: $measure-lg;
                margin-left: $measure-lg;
            }

            @include for-size(tablet) {
                font-size: $font-md;
            }
    
            @include for-size(tablet-md) {
                font-size: $font-lg;
            }

            @include for-size(tablet-md) {
                margin-top: $measure-xl;
                margin-left: $measure-xl;
            }

            @include for-size(desktop-md) {
                font-size: $font-xl;
                margin-top: $measure-xxl;
                margin-left: $measure-xxl;
            }
        }
    }
}

.section-intro {
    background-color: $darkgrayishblue;
    
    
    > .intro-werbetechnik {
        text-align: center;
        
        > h2 {
            color: $greenyellow;
        }

        > p {
            color: white;
        }
    }

    > .intro-signaletik {
        text-align: center;

        > h2 {
            color: $greenyellow;
            margin-bottom: 0;
        }

        > p {
            color: white;

            > a {
                color: #fff;

                &:hover {
                    transition: $hover;
                    color: $greenyellow;
                }
            }
        }
    }
}

.section-buttons {
    background-color: #fff;
    padding: 0 $measure-md;

    @include for-size(phone) {
        padding: 0 $measure-lg;
    }

    > .button-container {
        display: flex;
        flex-direction: column;
        margin-bottom: -$measure-md;

        @include for-size(phone) {
            margin-bottom: -$measure-lg;
        }

        @include for-size(tablet-md) {
            flex-direction: row;
            justify-content: center;
            margin-right: -$measure-lg;
            flex-wrap: wrap;
        }
        
        > button {
            color: $darkgrayishblue;
            text-transform: uppercase;
            font-weight: 600;
            font-size: $font-md;
            padding: $measure-lg;
            margin-bottom: $measure-md;
            background-color: $greenyellow;

            &:hover {
                transition: $hover;
                background-color: $darkgrayishblue;
                color: $greenyellow;
            }

            @include for-size(phone) {
                font-size: $font-lg;
                margin-bottom: $measure-lg;
            }

            @include for-size(tablet-md) {
                flex: 0 0 calc(50% - #{$measure-lg});
                height: $measure-xxl;
                margin-right: $measure-lg;
            }

            @include for-size(desktop) {
                flex: 0 0 calc(33.3333% - #{$measure-lg});
            }

            @include for-size(laptop) {
                font-size: $font-lg;
                height: 128px;
            }

            > svg {
                margin-right: $measure-md;
            }
        }
    }
}

.section-company {
    background-color: $darkgrayishblue;

    > .intro-company {
        text-align: center;

        > h2 {
            color: $greenyellow;
        }
        
        > p {
            color: #fff;
        }
    }

    > .employee-container {
        display: flex;
        flex-direction: column;
        margin-bottom: -$measure-md;

        @include for-size(phone) {
            margin-bottom: -$measure-lg;
        }  

        @include for-size(tablet-md) {
            flex-direction: row;
            margin-right: -$measure-lg;
            flex-wrap: wrap;
            justify-content: center;
        }

        > .employee {
            text-align: center;

            @include for-size(tablet-md) {
                flex: 0 0 calc(50% - #{$measure-lg});
                margin-right: $measure-lg;
            }

            @include for-size(desktop) {
                flex: 0 0 calc(33.3333% - #{$measure-lg});
            }
            
            > .employee-info {
                padding: $measure-md 0;

                @include for-size(phone) {
                    padding: $measure-lg 0;
                }   
                
                > p {
                    color: #fff;
                    margin-bottom: $measure-sm;
                }
    
                > a {
                    color: $greenyellow;
                    margin-bottom: $measure-md;

                    &:hover {
                        transition: $hover;
                        font-size: $scale-font-md;
                    }
                }
            }
        }
    }
}

.section-services {
    background-color: $greenyellow;

    > .intro-services {
        text-align: center;
        margin-bottom: $measure-xs;

        > h2 {
            color: $darkgrayishblue;
        }
        
        > p {
            color: red;
            font-weight: bold;
        }

        > img {
            max-width: 100%;
        }
    } 
  
    > .service-container {
        display: flex;
        flex-direction: column;
        margin-bottom: -$measure-md;

        @include for-size(phone) {
            margin-bottom: -$measure-lg;
        }

        @include for-size(tablet-md) {
            flex-direction: row;
            justify-content: center;
            margin-right: -$measure-lg;
            flex-wrap: wrap;
        }

        &.featured {
            margin-bottom: $measure-md;

            @include for-size(tablet) {
                margin-bottom: $measure-lg;
            }
        }

        > .service {
            background-color: $darkgrayishblue;
            margin-bottom: $measure-md;
            text-align: center;
            align-self: center;
            cursor: pointer;

            @include for-size(phone) {
                margin-bottom: $measure-lg;
            }

            &:hover {
                transition: $hover;
                transform: $scale;
            }

            @include for-size(tablet-md) {
                margin-right: $measure-lg;
                flex: 0 0 calc(50% - #{$measure-lg});
            }

            @include for-size(desktop-md) {
                flex: 0 0 calc(25% - #{$measure-lg});
            }

            &.featured {
                margin-bottom: 0px;
            }

            > p {
                color: #fff;
                font-size: $font-sm;
                padding: $measure-md;
                text-transform: uppercase;
                font-weight: 600;

                @include for-size(phone) {
                    font-size: $font-md;
                }
            }
        }
    }
}

.section-implementation-example {
    background-color: $darkgrayishblue;

    > .implementation-example-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        
        > img {
            margin-bottom: $measure-md;

            @include for-size(phone) {
                margin-bottom: $measure-lg;
            }
        }
    }
    
    > p {
        text-align: center;
        color: #fff;
    }
}

.section-reference {
    
    > h2 {
        text-align: center;
        color: $darkgrayishblue;
    }
}

.section-impressions {
    background-color: $darkgrayishblue;

    > h2 {
        text-align: center;
        color: #fff;
    }
}

.section-sponsoring {
    background-color: $darkgrayishblue;

    > .intro-sponsoring {
        text-align: center;

        > h2 {
            color: $greenyellow;
        }
        
        > p {
            color: #fff;
        }

        > img {
            max-width: 100%;
        }
    }

    > .sponsor-container {
        display: flex;
        flex-direction: column;
        margin-bottom: -$measure-md;

        @include for-size(phone) {
            margin-bottom: -$measure-lg;
        }  

        @include for-size(tablet-md) {
            flex-direction: row;
            margin-right: -$measure-lg;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
        }

        > .sponsor {
            margin-bottom: $measure-md;

            @include for-size(tablet-md) {
                flex: 0 0 calc(50% - #{$measure-lg});
                margin-right: $measure-lg;
            }

            @include for-size(desktop) {
                flex: 0 0 calc(33.3333% - #{$measure-lg});
            }
        }
    }
}

.section-contact-form {
    background-color: $greenyellow;
    
    > .intro-contact-form-container {
        display: flex;
        flex-direction: row;
        justify-content: center;

        > .intro-contact-form {
            text-align: center;
            color: $darkgrayishblue;

            @include for-size(desktop) {
                max-width: $laptop;
            }

            > h2 {
                color: $darkgrayishblue;
            }

            > p {
                color: $darkgrayishblue;
            }
        }
    }

    > form {
        display: flex;
        flex-direction: row;
        
        @include for-size(desktop) {
            justify-content: center;
        }

        > .contact-form-container {
            width: 100%;

            @include for-size(desktop) {
                max-width: $laptop;
            }

            > .inputs-container {
                display: flex;
                flex-direction: column;
    
                @include for-size(tablet-md) {
                    flex-direction: row;
                    justify-content: center;
                    margin-right: -$measure-lg;
                }
    
                > input {
                    margin-bottom: $measure-lg;

                    @include for-size(phone) {
                        margin-bottom: $measure-xl;
                    }
    
                    @include for-size(tablet-md) {
                        flex: 1 0 calc(33.3333% - #{$measure-lg});
                        margin-right: $measure-lg;
                    }
                }
            }
    
            > .textarea-container {
                margin-bottom: $measure-lg;
        
                > textarea {
                    resize: none;
                    width: 100%;
                }

                textarea::-webkit-scrollbar {
                    width: $scrollbar-width;
                }
                  
                textarea::-webkit-scrollbar-track {
                    background: #fff;
                }
                  
                textarea::-webkit-scrollbar-thumb {
                    background: $darkgrayishblue;
                }
            }

            > .g-recaptcha {
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-bottom: $measure-lg;
                transform-origin: 0 0;
                margin-right: -$measure-md;
                margin-left: -$measure-md;

                @include for-size(phone) {
                    margin-right: 0;
                    margin-left: 0;
                }
                
                @include for-size(tablet-md) {
                    justify-content: flex-start;
                }
            }

            > .contact-form-submit-btn {
                width: 100%;
                
                @include for-size(tablet-md) {
                    width: 128px;
                }
            }
        }
    }
}

.section-location {
    
    > h2 {
        text-align: center;
        color: $darkgrayishblue;
    }

    > #map {
        width: 100%;
        height: 320px;
        margin-bottom: $measure-lg;

        @include for-size(phone) {
            margin-bottom: $measure-xl;
        }

        @include for-size(tablet-md) {
            height: 600px;
        }
    }

    > .location-info-container {
        display: flex;
        flex-direction: column;
        margin-bottom: -$measure-lg;

        @include for-size(tablet-md) {
            flex-direction: row;
            justify-content: space-evenly;
        }

        > .location-info {
            text-align: center;
            margin-bottom: $measure-lg;

            > svg {
                color: $darkgrayishblue;
                font-size: $font-lg;
                margin-bottom: $measure-sm;
            }

            > address {
                color: $darkgrayishblue;
            }

            > p {
                color: $darkgrayishblue;
            }

            > a {
                display: block;
                color: $darkgrayishblue;

                &:hover {
                    transition: $hover;
                    color: $greenyellow;
                }
            }
        }
    }
}