* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: $font-sm;
    scroll-behavior: initial !important;
    
    @include for-size(phone) {
        font-size: $font-md;
    }

    @include for-size(laptop) {
        font-size: $font-md;
        scroll-behavior: smooth !important;
    }
}

body {
    font-family: 'Barlow', 'sans-serif';
    font-weight: 400;
    letter-spacing: $letter-spacing;
}

h2 {
    font-size: $font-md;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: $measure-md;

    @include for-size(phone) {
        margin-bottom: $measure-lg;
        font-size: $font-lg;
    }
}

article:last-child {
    margin-bottom: 0;
}
article:first-child {
    margin-bottom: $measure-lg;

    @include for-size(phone) {
        margin-bottom: $measure-xl;
    }
}

section {
    padding: $measure-md;
    margin-bottom: $measure-md;

    @include for-size(phone) {
        padding: $measure-lg;
        margin-bottom: $measure-lg;
    }

    @include for-size(desktop) {
        padding: $measure-xl;
    }
}

p {
    margin: 0;
}

a {
    text-decoration: none;
}

button {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

img {
    max-width: 100%;
}

address {
    font-style: normal;
    margin: 0;
}

ul {
    padding: 0;
    margin: 0;
}

input[type=text], input[type=email], input[type=tel], textarea {
    font-size: $font-sm;
    background-color: $greenyellow;
    border: none;
    border-bottom: $border-width solid $darkgrayishblue;
    font-family: 'Barlow', sans-serif;
    letter-spacing: $letter-spacing;
    font-weight: 600;
    color: $darkgrayishblue;

    @include for-size(phone) {
        font-size: $font-md;
    }
}

input[type=text]::placeholder, input[type=email]::placeholder, input[type=tel]::placeholder, textarea::placeholder {
    color: $darkgrayishblue;
    font-size: $font-sm;
    text-transform: uppercase;

    @include for-size(phone) {
        font-size: $font-md;
    }
}

input[type=text]:focus, input[type=email]:focus, input[type=tel]:focus, textarea:focus {
    outline: none;
    transition: $hover;
    border-bottom: $border-width solid #fff;
}

input[type=submit] {
    width: 128px;
    font-family: 'Barlow', sans-serif;
    letter-spacing: $letter-spacing;
    padding: $measure-md;
    font-weight: 600;
    font-size: $font-sm;
    background-color: $greenyellow;
    border: $border-width solid $darkgrayishblue;
    text-transform: uppercase;
    cursor: pointer;
    color: $darkgrayishblue;

    @include for-size(phone) {
        font-size: $font-md;
    }

    &:disabled {
        opacity: 0.5;
    }

    &:hover:enabled {
        transition: $hover;
        background-color: $darkgrayishblue;
        color: $greenyellow;
    }
}

.app {
    display: grid;
    grid-template-areas: 
        "sections"
        "footer";
    
    @include for-size(laptop) {
        grid-template-areas: 
            "menu   sections"
            "menu footer";
    }
}

.menu {
    display: none;

    @include for-size(laptop) {
        display: block;
        grid-area: menu;
        padding: $measure-lg;
    }

    > .menu-btn {

        @include for-size(laptop) {
            top: $measure-lg;
            position: sticky;
        }
    }
}

.sections {
    grid-area: sections;
}

footer {
    grid-area: footer;
    padding-top: $measure-md;

    @include for-size(phone) {
        padding-top: $measure-lg;
    }

    @include for-size(tablet-md) {
        padding: $measure-xl $measure-xl;
    }
    
    > .social-media-container {
        display: flex;
        justify-content: center;
        margin-bottom: $measure-lg;

        > a {
            margin-right: $measure-md;

            > svg {
                font-size: $font-xxl;
                color: $darkgrayishblue;

                &:hover {
                    transition: $hover;
                    color: $greenyellow;
                }
            }
        }

        > a:last-child {
            margin-right: 0;
        }
    }

    > .links {
        list-style-type: none;
        color: $darkgrayishblue;
        text-align: center;
        margin-bottom: $measure-lg;

        @include for-size(tablet-md) {
            margin-bottom: 0;
        }

        > li {

            > a {
                color: $darkgrayishblue;

                &:hover {
                    transition: $hover;
                    color: $greenyellow;
                }
            }
        }
    }

    > .c-container {
        background-color: $darkgrayishblue;
        display: flex;
        justify-content: center;

        @include for-size(tablet-md) {
            display: none;
        }

        > a {
            
            > img {
                height: 128px;
                padding: $measure-lg 0;
            }
        }
    }
}