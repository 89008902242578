.menu-btn {

    > svg {
        color: $greenyellow;
        font-size: 32px;
        cursor: pointer;

        @include for-size(phone) {
            font-size: 48px;
        }
        
        &:hover {
            transition: $hover;
            transform: $scale;
        }
    }
}

.space {
    margin-bottom: $measure-md;

    @include for-size(phone) {
        margin-bottom: $measure-lg;
    }
}

.double-space {
    margin-bottom: $measure-lg;

    @include for-size(phone) {
        margin-bottom: $measure-lg;
    }
}

.greenyellow {
    color: $greenyellow !important;
}

.strong {
    font-weight: bold;
}

.impression-gallery-container {

    > .impression-gallery-button-container {
        display: flex;
        flex-direction: column;
        margin-bottom: 0;

        @include for-size(phone) {
            margin-bottom: $measure-md;
        }

        @include for-size(tablet-md) {
            flex-direction: row;
            justify-content: center;
            margin-right: -$measure-md;
            flex-wrap: wrap;
        }

        > button {
            background-color: $greenyellow;
            text-transform: uppercase;
            font-weight: 600;
            padding: $measure-md;
            font-size: $font-sm;
            margin-bottom: $measure-md;

            &.active {
                background-color: #fff;
            }

            &:hover {
                transition: $hover;
                background-color: #fff;
            }

            @include for-size(tablet-md) {
                flex: 0 0 calc(33.3333% - #{$measure-md});
                margin-right: $measure-md;
            }

            @include for-size(desktop-sm) {
                flex: 0 0 calc(20% - #{$measure-md});
            }
        }
    }

    > div {
        display: flex;
        flex-direction: column;
        margin-bottom: -$measure-md;

        @include for-size(phone) {
            margin-bottom: -$measure-lg;
        }
    
        @include for-size(tablet-md) {
            flex-direction: row;
            flex-wrap: wrap;
            margin-right: -$measure-lg;
        }

        > .impression {
            margin-bottom: $measure-md;

            @include for-size(phone) {
                margin-bottom: $measure-lg;
            }
    
            @include for-size(tablet-md) {
                margin-right: $measure-lg;
                flex: 0 0 calc(50% - #{$measure-lg});
            }
    
            @include for-size(desktop-md) {
                flex: 0 0 calc(25% - #{$measure-lg});
            }

            &.fade {
                animation: $fade-anim;
            }
    
            &:hover {
                transition: $hover;
                transform: $scale;
                cursor: pointer;
            }
        }
    }
}

.reference-slideshow-container {
    display: flex;
    flex-direction: row;

    > .carousel-indicators {
        display: none;
    }

    > .carousel-inner {
        margin: 0;
        
        @include for-size(tablet-md) {
            margin: 0 calc(#{$font-xl} + #{$measure-xl});
        }
        
        > .active.carousel-item,
        .carousel-item-next,
        .carousel-item-prev {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;

            > .reference {
                margin-right: $measure-xl;

                > a {

                    > img {
                        height: 172px;
                    }
                }
            }

            > .reference:last-child {
                margin-right: 0;
            }
        }
    }
    
    > .carousel-control-prev {
        display: none;
        opacity: 1;
        width: auto;
        align-items: flex-start center;

        @include for-size(tablet-md) {
            display: flex;
        }
        
        > svg {
            transform: rotate(180deg);
            color: $darkgrayishblue;
            font-size: $font-lg;

            @include for-size(tablet-md) {
                font-size: $font-xl;
            }

            &:hover {
                transition: $hover;
                color: $greenyellow;
            }
        }
    }

    > .carousel-control-next {
        display: none;
        opacity: 1;
        width: auto;
        align-items: flex-end center;

        @include for-size(tablet-md) {
            display: flex;
        }

        > svg {
            color: $darkgrayishblue;
            font-size: $font-lg;

            @include for-size(tablet-md) {
                font-size: $font-xl;
            }

            &:hover {
                transition: $hover;
                color: $greenyellow;
            }
        }
    }
}

.modal {

    > .modal-dialog {

        > .modal-content {
            border: none;
            border-radius: 0;

            > .modal-header {
                border: none;
                border-radius: 0;
                background-color: $darkgrayishblue;
                
                > .modal-title {
                    color: $greenyellow;
                    text-transform: uppercase;
                    font-weight: 600;
                    font-size: $font-md;

                    @include for-size(phone) {
                        font-size: $font-lg;
                    }

                    > svg {
                        margin-right: $measure-md;
                    }
                }

                > .btn-close {
                    filter: $filter-greenyellow;
                    width: $font-md;
                    height: $font-md;

                    @include for-size(phone) {
                        width: $font-lg;
                        height: $font-lg;
                    }
                }
            }

            > .modal-body {

                 > p {
                     color: $darkgrayishblue;
                     font-size: $font-sm;

                     @include for-size(phone) {
                        font-size: $font-md;
                    }
                 }
            }
        }
    }
}

.menu-modal {

    > .modal-dialog {

        > .modal-content {

            > .modal-body {

                > .menu-modal-body-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-bottom: -$measure-md;

                    > a {
                        text-align: center;
                        width: 100%;
                        padding: $measure-md;
                        background-color: $greenyellow;
                        margin-bottom: $measure-md;
                        font-weight: bold;
                        text-transform: uppercase;
                        color: $darkgrayishblue;

                        &:hover {
                            transition: $hover;
                            background-color: $darkgrayishblue;
                            color: $greenyellow;
                        }
                    }
                }
            }
        }
    }
}

.sicht-und-sonnenschutz-modal {

    > .modal-dialog {

        > .modal-content {

            > .modal-body {

                > a {
                    color: $darkgrayishblue;

                    &:hover {
                        transition: $hover;
                        color: $greenyellow;

                        > svg {
                            transition: $hover;
                            filter: $filter-greenyellow;
                        }
                    }

                    svg {
                        margin-right: $measure-md;
                    }
                }
            }
        }
    }
}

.contact-form-recaptcha-modal {

    > .modal-dialog {

        > .modal-content {

            > .modal-body {

                > #g-recaptcha-contact-form {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    transform: scale(0.925);

                    @include for-size(phone) {
                        transform: scale(1);
                    }
                }
            }

            > .modal-footer {
                justify-content: center;

                > input[type=submit] {
                    border: none;
                }
            }
        }
    }
}

.loading-modal {

    > .modal-dialog {

        > .modal-content {

            > .modal-header {

                > .modal-title {

                    > .please-wait-container {

                        > .spinner-border.text-success {
                            color: $greenyellow !important;
                            width: $font-md;
                            height: $font-md;
                        }

                        > .please-wait-text {
                            margin-left: $measure-md;
                        }
                    }
                }
            }
        }
    }
}