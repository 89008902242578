@mixin for-size($size) {
    @if $size == phone-sm {
        @media (max-width: #{$phone-sm}) {
            @content;
        }
    } @else if $size == phone {
        @media (min-width: #{$phone}) {
            @content;
        }
    } @else if $size == tablet {
        @media (min-width: #{$tablet}) {
            @content;
        }
    } @else if $size == tablet-md {
        @media (min-width: #{$tablet-md}) {
            @content;
        }
    } @else if $size == laptop {
        @media (min-width: #{$laptop}) {
            @content;
        }
    } @else if $size == desktop {
        @media (min-width: #{$desktop}) {
            @content;
        }
    } @else if $size == desktop-sm {
        @media (min-width: #{$desktop-sm}) {
            @content;
        }
    } @else if $size == desktop-md {
        @media (min-width: #{$desktop-md}) {
            @content;
        }
    }
}