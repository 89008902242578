$greenyellow: #c3d305;
$darkgrayishblue: #23232d;

$filter-greenyellow: invert(81%) sepia(51%) saturate(1680%) hue-rotate(13deg) brightness(94%) contrast(101%);
$filter-darkgrayishblue: invert(10%) sepia(30%) saturate(574%) hue-rotate(190deg) brightness(102%) contrast(86%);

$phone-sm: 399px;
$phone: 499px;
$tablet: 600px;
$tablet-md: 825px;
$laptop: 1075px;
$desktop: 1212px;
$desktop-sm: 1375px;
$desktop-md: 1550px;

$font-xxl: 50.5px;
$font-xl: 40.4px;
$font-lg: 30.3px;
$font-md: 20.2px;
$font-sm: 14.4px;
$font-xs: 11.1px;
$font-xxs: 8.8px;

$measure-xxl: 96px;
$measure-xl: 48px;
$measure-lg: 24px;
$measure-md: 12px;
$measure-sm: 6px;
$measure-xs: 3px;
$measure-xss: 1.5px;

$border-width: 3px;

$scrollbar-width: 5px;

$scale: scale(1.03);
$scale-font-md: 21.23px;
$hover: 0.2s ease-out;

$letter-spacing: 0.8px;

$fade-anim: fade 1s ease-in-out;
@keyframes fade {
    0%   {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}